* {
  box-sizing: border-box;
}

body {
  color: #fff;
  background: #2e2e2e;
  margin: 0 auto;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.container {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: auto;
  display: flex;
}

.form-wrapper, .form {
  display: flex;
}

input, button {
  border: 1px solid #dedede;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin: 0;
  padding: 8px 10px;
  font-size: 14px;
}

input[type="text"] {
  width: 300px;
}

.search-btn {
  cursor: pointer;
  border-left: 0;
  border-radius: 0 4px 4px 0;
}

.random-btn {
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-left: 10px;
}

.meals {
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
  display: grid;
}

.meal {
  text-align: center;
  cursor: pointer;
  width: 150px;
  height: 150px;
  position: relative;
}

.meal > img {
  border: 2px solid #e9e3e3;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
}

.meal-info {
  opacity: 0;
  background: #000000b3;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity .2s ease-in;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.meal:hover .meal-info {
  opacity: 1;
}

.single-meal {
  width: 800px;
  margin: 30px auto;
}

.single-meal > img {
  border: 2px solid #dedede;
  border-radius: 2rem;
  width: 300px;
  margin: 15px;
}

.single-meal-info {
  border: 2px dashed #fefefe;
  margin: 20px;
  padding: 20px;
}

.single-meal-details > p {
  letter-spacing: .5px;
  margin: 0;
  line-height: 1.5;
}

.single-meal-details > ul {
  padding-left: 0;
  list-style-type: none;
}

.single-meal-details > ul > li {
  color: #2e2e2e;
  background: #e2e2e2;
  border: 1px solid #fefefe;
  border-radius: .8rem;
  margin: 0 5px 5px 0;
  padding: 8px 5px;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
}

/*# sourceMappingURL=index.a4c42cc6.css.map */
