* {
    box-sizing: border-box;
}

body {
    background: #2e2e2e;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #fff;
    margin: 0 auto;
}

.container {
    margin: auto;
    display: flex;
    flex-direction: column;
    max-width: 800px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.form-wrapper,
.form {
    display: flex;
}

input, button {
    border: 1px solid #dedede;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 14px;
    padding: 8px 10px;
    margin: 0;
}

input[type='text']{
    width: 300px;
}

.search-btn {
    cursor: pointer;
    border-left: 0;
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.random-btn {
    cursor: pointer;
    margin-left: 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.meals {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
}

.meal {
    position: relative;
    height: 150px;
    width: 150px;
    text-align: center;
    cursor: pointer;
}

.meal > img {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    border: 2px solid #e9e3e3;
}

.meal-info {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.7);
    opacity: 0;
    border-radius: 1rem;
    transition: opacity 0.2s ease-in;
}

.meal:hover .meal-info {
    opacity: 1;
}

.single-meal {
    margin: 30px auto;
    width: 800px;
}

.single-meal > img {
    width: 300px;
    margin: 15px;
    border: 2px solid #dedede;
    border-radius: 2rem;
}

.single-meal-info {
    margin: 20px;
    padding: 20px;
    border: 2px dashed #fefefe;
}

.single-meal-details > p {
    margin: 0;
    letter-spacing: 0.5px;
    line-height: 1.5;
}

.single-meal-details > ul {
    padding-left: 0;
    list-style-type: none;
}
.single-meal-details > ul > li {
    border: 1px solid #fefefe;
    background: #e2e2e2;
    border-radius: .8rem;
    color: #2e2e2e;
    display: inline-block;
    padding: 8px 5px;
    font-size: 12px;
    font-weight: 700;
    margin: 0 5px 5px 0;
}

